import React from "react";

const me = require("../../images/me3.png").default;

const Jumbo = () => {
  return (
    <div className="pt-20 bg-amber-200 h-screen flex justify-center px-3">
      <div className="container flex flex-col lg:flex-row justify-between overflow-hidden">
        <div className="my-auto items-center flex lg:w-2/5">
          <div>
            <h1 className="font-display sm:text-2xl md:text-3xl xl:text-5xl text-xl">
              Eleftherios Psitopoulos
            </h1>
            <b className="text-xl mb-4 block">
              Front end developer. Scrum master. Stuff creator.
            </b>
            <p className="lg:text-lg">
              I believe great things can be achieved through the use of
              technology and research. But those are merely the tools. Behind
              the tools are the people. Teamwork and passion, those are what
              matter the most.
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full lg:w-3/5">
          <img className="object-contain object-bottom" src={me} />
        </div>
      </div>
    </div>
  );
};

export default Jumbo;
